import InputFields from "../../components/common/InputField";
import { useForm } from "react-hook-form";
import SelectButtonUserManagement from "./SelectButtonUserManagement";
import Button from "../common/Button";
import ArrowIcon from "../../assets/images/BackArrow.svg";
import InfoCircle from "../../assets/images/info-circle (1).svg";
import InputWithCountryCode from "../common/InputWithCountryCode";
import { useEffect, useState } from "react";
import { axiosUAMInstance } from "../../utils/axios";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import FooterText from "../common/FooterText";
interface Option {
  value: number;
  label: string;
}

const EditUserManagement = () => {
  const [optionData, setOptionData] = useState<Option[]>([]);
  const [selectedFunc, setSelectedFunc] = useState<string | null>(null);
  //   const [selectedFunId, setSelectFunId] = useState<number | null>(null);
  const [isEmptyPhonNumber, setEmptyPhonNumber] = useState<boolean>(false);
  const [isValidPhnNum, setValidPhmNum] = useState<boolean>(false);

  const navigate = useNavigate();

  const [loader, setLoader] = useState<boolean>(false);
  const location = useLocation();
  const userData = location.state.userData;
  const currentPage = location.state.currentPage;
  console.log(currentPage, "currentPage");
  const isActive = userData.isActive;
  const Id = userData.id;

  const handleSetFunc = (data: any) => {
    setSelectedFunc(data.label);
    setSelectFunId(data.value);
  };
  const [selectedFunId, setSelectFunId] = useState<number | null>(
    userData ? userData.roleId : ""
  );

  //   const [phone, setPhone] = useState<string | null>("");
  const [phone, setPhone] = useState<string | null>(
    userData ? userData.mobile : ""
  );

  const handleOnChange = (value: string) => {
    setPhone(value);
    if (value === "") {
      setEmptyPhonNumber(true);
    } else {
      setEmptyPhonNumber(false);
    }

    if (value.length === 10 || value === "") {
      setValidPhmNum(false);
    } else {
      setValidPhmNum(true);
    }
  };

  function filterById(id: any) {
    return optionData.filter((item: any) => item?.value === id);
  }

  const roleId = filterById(userData.roleId);
  //   console.log(roleId[0].label, "roleid");
  const defaultSelected = roleId.length > 0 ? roleId[0].label : "";

  const getAdminRoleList = () => {
    axiosUAMInstance
      .get(`/admin/role/list`, {
        params: {
          page: 1,
          pageSize: 100,
        },
      })
      .then((response) => {
        // console.log(response?.data.roles, "admin/role/list");
        setOptionData(
          response?.data?.roles?.map((f: any) => ({
            value: f.id,
            label: f.compositeRoleName,
          }))
        );
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getAdminRoleList();
  }, []);
  const handleBack = () => {
    const currentPage = location.state?.currentPage || 1;
    navigate("/usermanagement/user", { state: { currentPage } });
  };

  const countryCodes = [{ code: "+91", country: "India" }];
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (userData) {
      setValue("firstName", userData?.firstName || "");
      setValue("MiddleName", userData?.middleName || "");
      setValue("LastName", userData?.lastName || "");
      setValue("email", userData?.emailId || "");
    }
  }, [userData, setValue]);

  const onSubmit = (data: any) => {
    // console.log(data, "data");

    if (isEmptyPhonNumber) {
      return; // Do nothing if
    }

    if (isValidPhnNum) {
      return; // Do nothing if
    }

    const { firstName, LastName, MiddleName, email } = data;
    setLoader(true);

    axiosUAMInstance
      .put(`admin/user/update`, {
        id: Id,
        first_name: firstName,
        middle_name: MiddleName,
        last_name: LastName,
        role_id: selectedFunId,
        mobile: phone,
        email_id: email,
        isActive: isActive,
        password: "admin",
      })
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          Swal.fire({
            title: ` User creation Updated successfully`,
            icon: "success",
          });
        }

        navigate("/usermanagement/user");
        reset();
        setSelectedFunc(null);
        setPhone("");
      })
      .catch((error) => {
        setLoader(false);
        Swal.fire({
          title: error?.response?.data?.error?.errorMessage,
          icon: "error",
        });
      });
  };

  const onClickSubmitButton = () => {
    if (phone === "") {
      setEmptyPhonNumber(true);
    }
  };

  return (
    <div
      className="mt-6 flex flex-col h-full justify-between"
      style={{ minHeight: "calc(100vh - 265px)" }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 md:grid-cols-3 gap-4"
      >
        <div>
          <label
            htmlFor="firstName"
            className="text-base font-normal text-gilroy-medium"
          >
            First name <span className="text-red-500">*</span>
          </label>
          <div className="mt-2">
            <InputFields
              {...register("firstName", {
                required: "First name is required",
                minLength: {
                  value: 3,
                  message: "First name must be at least 3 characters",
                },
              })}
              placeholder="Type input"
              variant="basic"
              width="350px"
            />
            {errors.firstName?.message &&
              typeof errors.firstName.message === "string" && (
                <p className="text-red-500">{errors.firstName.message}</p>
              )}
          </div>
        </div>
        <div>
          <label
            htmlFor="MiddleName"
            className="text-base font-normal text-gilroy-medium"
          >
            Middle name
          </label>
          <div className="mt-2">
            <InputFields
              {...register("MiddleName", {
                // required: "middle is required",
                // minLength: {
                //   value: 3,
                //   message: "middle name must be at least 3 characters",
                // },
              })}
              placeholder="Type input"
              variant="basic"
              width="350px"
            />
            {errors.MiddleName?.message &&
              typeof errors.MiddleName.message === "string" && (
                <p className="text-red-500">{errors.MiddleName.message}</p>
              )}
          </div>
        </div>
        <div>
          <label
            htmlFor="LastName"
            className="text-base font-normal text-gilroy-medium"
          >
            Last name
          </label>
          <div className="mt-2">
            <InputFields
              {...register("LastName", {
                required: "LastName  is required",
              })}
              placeholder="Type input"
              variant="basic"
              width="350px"
            />
            {errors.LastName?.message &&
              typeof errors.LastName.message === "string" && (
                <p className="text-red-500">{errors.LastName.message}</p>
              )}
          </div>
        </div>
        {/* <div>
          <label
            htmlFor="role"
            className="text-base font-normal text-gilroy-medium"
          >
            Role <span className="text-red-500">*</span>
          </label>
          <div className="mt-2">
            <SelectButtonUserManagement
              setOption={handleSetFunc}
              options={(optionData as any) || []}
              selectedOption={selectedFunc || defaultSelected}
              placeholder="Select"
              mdWidth="md:w-[350px]"
            />
          </div>
        </div> */}
        <div>
          <label
            htmlFor="email"
            className="text-base font-normal text-gilroy-medium"
          >
            Email ID <span className="text-red-500">*</span>
          </label>
          <div className="mt-2">
            <InputFields
              {...register("email", {
                required: "Email is required",
                pattern: { value: /^\S+@\S+$/i, message: "Invalid email" },
              })}
              placeholder="Type input"
              variant="basic"
              width="350px"
            />
            {errors.email?.message &&
              typeof errors.email.message === "string" && (
                <p className="text-red-500">{errors.email.message}</p>
              )}
          </div>
        </div>
        <div>
          <label
            htmlFor="uniqueID"
            className="text-base font-normal text-gilroy-medium"
          >
            <div className="flex">
              Mobile no <span className="text-red-500">*</span>
              <img src={InfoCircle} alt=" InfoCircle " className="w-5 mx-2" />
            </div>
          </label>
          <div className="mt-2">
            <InputWithCountryCode
              initialCountryCode="+91"
              initialPhoneNumber={userData ? userData.mobile : ""}
              countryCodes={countryCodes}
              onCountryCodeChange={(code) =>
                console.log("Selected country code:", code)
              }
              onPhoneNumberChange={(phoneNumber) => handleOnChange(phoneNumber)}
            />
            {isEmptyPhonNumber && (
              <p className="text-red-500">please enter your phone number</p>
            )}
            {isValidPhnNum && (
              <p className="text-red-500">
                Phone number should be in 10 digits
              </p>
            )}
          </div>
        </div>
      </form>
      <div className="">
        <div className="col-span-1 md:col-span-3 flex justify-between items-center  p-2 mt-40">
          <div
            className="flex items-center cursor-pointer"
            // onClick={() => navigate(-1)}
            onClick={handleBack}
          >
            <img src={ArrowIcon} alt="ArrowIcon" className="w-6 h-6 mr-2" />
            <h1 className="text-sm font-normal text-black">Back</h1>
          </div>
          <div className="flex-grow"></div>{" "}
          <div
            className="w-24 "
            // onClick={onClickSubmitButton}
            onClick={handleSubmit(onSubmit)}
          >
            <Button
              loader={loader}
              type="submit"
              label="Submit"
              className=" h-10 "
              variant="basic"
            />
          </div>
        </div>
        <div className="border-[#E6E6E6] border-[1px] w-full"></div>
        {/* <div className="text-gilroy-light text-[#24222B] text-xs cursor-pointer h-10 flex items justify-center items-center">
          <div>© 2024 Protean BUDs, All Rights Reserved.</div>
        </div> */}
        <FooterText />
      </div>
    </div>
  );
};

export default EditUserManagement;
