import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ErrorCircle from "../../assets/images/error-circle.svg";
import add from "../../assets/images/add.svg";
import ButtonComp from "../cms/ButtonComp";
import { axiosUAMInstance } from "../../utils/axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

interface ReturnModelPopupProps {
  onClose: () => void;
  onSave: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const ReferModelPopup: React.FC<ReturnModelPopupProps> = ({
  onClose,
  onSave,
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const location = useLocation();
  const depositTakerId = location.state?.depositTakerId;
  const status = location?.state?.status;
  console.log(typeof status, "status");

  const navigate = useNavigate();
  const approverId = parseInt(sessionStorage.getItem("userId") || "0");

  const handleCloseModal = () => {
    onClose();
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoader(true);

      const response = await axiosUAMInstance.post(
        `/approval-engine/update-status`,
        {
          uniqueId: depositTakerId,
          // status: "REFER_TO_REGULATOR",
          status:
            status === "MOD_PENDING" || status === "MOD_TRANSIT"
              ? "MOD_REFER_TO_REGULATOR"
              : "REFER_TO_REGULATOR",
          approverId: approverId,
        }
      );
      setLoader(false);

      if (response) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Deposit taker referred to regulator successfully.",
        });

        setTimeout(() => {
          onClose();
          onSave();
          navigate("/mytask/deposit");
        }, 2500);
      }
    } catch (error: any) {
      setLoader(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Internal Server Error",
      });
    }
  };

  return (
    <>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleCloseModal}
      >
        <Box sx={style}>
          <div className="md:flex">
            <div className="m-[16px] md:m-16 w-[350px] md:w-[500px] lg:w-[500px] rounded-2xl p-[8px] text-gilroy-medium pb-[32px] shadow-xl bg-white">
              <div
                className="flex flex-row justify-end mb-[12px] cursor-pointer"
                onClick={handleCloseModal}
              >
                <img src={add} className="w-6 h-6" alt="icon" />
              </div>
              <div className=" flex flex-col  justify-center items-center">
                <img
                  src={ErrorCircle}
                  alt="ErrorCircle "
                  className="w-12 my-2"
                />
                <h1 className="text-xl font-normal text-gilroy-medium">
                  Are you sure you want to refer?
                </h1>
              </div>
              <form onSubmit={handleFormSubmit}>
                <div>
                  <hr className="w-full bg-[#E6E6E6] mt-[27px] mb-[24px]"></hr>
                  <ButtonComp onClose={onClose} title="Refer" loader={loader} />
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ReferModelPopup;
