import { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import ReactTable from "../../components/common/ReactTable";
import ActionButton from "../../components/common/ActionButton";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import { createColumnHelper } from "@tanstack/react-table";
import UmTabs from "../../components/UserManagement/UmTabs";
import searchButton from "../../assets/images/search-normal.svg";
import addCircle from "../../assets/images/add-circleb.svg";
import CustomPagination from "../../components/cms/Pagination/CustumPagination";
import AddNewRoleModal from "../../components/UserManagement/AddNewRoleModal";
import SelectButtonUserManagement from "../../components/UserManagement/SelectButtonUserManagement";
import InputFields from "../../components/form/InputField";
import useFetchFunctionalityForUAM from "../../hooks/useFetchFunctionalityForUAM";
import uamStore from "../../state/uamStore";
import { axiosUAMInstance } from "../../utils/axios";
import LoaderSpin from "../../components/LoaderSpin";
import useCommonStore from "../../state/commonStore";

type TableType = {
  sno: number;
  id: number;
  compositeRoleName: string;
  isActive: boolean;
};

const columnHelper = createColumnHelper<TableType>();

const RoleCreation = () => {
  const { toggleModal } = useCommonStore((state) => state);
  const [selectedFuncs, setSelectedFuncs] = useState<any[]>([]);
  const [roleEditId, setRoleEditId] = useState<number | undefined>();
  const [roleName, setRoleName] = useState<string | undefined>(undefined);
  const isRoleAdded = uamStore((state) => state.roleUpdate);
  const [roles, setRoles] = useState([]);
  const { uamFunctionalities } = useFetchFunctionalityForUAM();
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedFunc, setSelectedFunc] = useState<string | null>(null);
  const [resetSearch, setResetSearch] = useState(false);
  // console.log(selectedFunc, "selected fun");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isActive, setIsActive] = useState();
  const [total, setTotal] = useState(0);
  const [modalOpenClose, setModalOpenClose] = useState<boolean>(false);
  const [RoleNameId, setRoleNameId] = useState<string>("");
  const handleModal = () => {
    toggleModal(!modalOpenClose);
    setModalOpenClose(!modalOpenClose);
  };

  const apiCall = () => {
    setLoader(true);
    axiosUAMInstance
      .get("/admin/role/list", {
        params: {
          page: page,
          pageSize: pageSize,
          search: searchQuery,
          functionality: RoleNameId,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRoles(res?.data.roles);
          setTotal(res?.data?.totalData);
        }
        setLoader(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    apiCall();
  }, [isRoleAdded, page, resetSearch]);

  let count = (page - 1) * pageSize;

  const columns = [
    columnHelper.accessor("sno", {
      cell: () => {
        while (count <= total) {
          count++;
          return count;
        }
      },
      header: () => <span>S. No.</span>,
    }),

    columnHelper.accessor("compositeRoleName", {
      cell: (info) => info.renderValue(),
      header: () => <span>Name of the Role</span>,
    }),
    columnHelper.accessor("isActive", {
      header: () => <span>Status</span>,
      cell: (info: any) => {
        const value = info?.row?.original?.isActive;
        const id = info?.row?.original?.id;
        // console.log(info?.row?.original?.id, "info");
        const StatusChange = () => {
          axiosUAMInstance
            .patch(`admin/role/status/`, {
              id: id,
              status: !value,
            })
            .then((response: any) => {
              apiCall();
            })
            .catch((error: any) => {});
        };

        return (
          <div
            className="flex flex-col md:flex-row justify-center gap-3"
            key={Math.random()}
          >
            <span>{value ? "Active" : "In Active"}</span>
            <ToggleSwitch enabled={value} apiCall={StatusChange} />
          </div>
        );
      },
    }),
    {
      accessorFn: (row: any) => row,
      id: "edit",
      cell: (info: any) => {
        const handleOnEdit = (event: any) => {
          const data = info.cell.row.original;
          console.log(data?.functionalities, "data functionality");
          setIsActive(data.isActive);
          setRoleName(data?.compositeRoleName);
          setRoleEditId(data?.id);
          let existanceIds: any[] = data?.functionalities?.map(
            (s: any) => s?.id
          );
          // const selectedFuncs = uamFunctionalities.filter(
          //   (f) => data?.functionalities?.includes(f.roleName) === true
          // );
          const selectedFuncs = uamFunctionalities.filter(
            (f) => existanceIds?.includes(f.value) === true
          );
          console.log(selectedFunc, "selectedfun");
          setSelectedFuncs(selectedFuncs);
          setModalOpenClose(true);
        };
        return (
          <>
            <ActionButton variant="edit" onClick={handleOnEdit} />
          </>
        );
      },
      header: () => <span>Edit</span>,
    },
  ];

  const handleSetFunc = (data: any) => {
    console.log(data, "fun");
    setSelectedFunc(data.label);
    setRoleNameId(data.roleName);
  };

  const hanldeSubmit = () => {
    setLoader(true);
    apiCall();
  };

  const handleSearch = (e: any) => {
    if (e.target.value === "") {
      setPage(1);
      setSearchQuery("");
      setResetSearch(!resetSearch);
    }
    setSearchQuery(e.target.value);
  };

  return (
    <div>
      <Layout
        layout={
          <div
            className={`relative mx-4 xl:ml-[40px] ${
              modalOpenClose ? "blur-sm" : ""
            }`}
            style={{ minHeight: "calc(100vh - 110px)" }}
          >
            <div className="mt-4 ">
              <UmTabs />
            </div>
            <div className="mt-[27px] mb-[35px] flex items-center  flex-wrap gap-4">
              <div className="mt-2">
                <InputFields
                  height="48px"
                  width="500px"
                  padding="10px"
                  placeholder="Search by Name/Role"
                  onChange={handleSearch}
                />
              </div>
              <SelectButtonUserManagement
                setOption={handleSetFunc}
                options={uamFunctionalities || []}
                selectedOption={selectedFunc}
                placeholder="Functionality"
              />

              {/* <div className="w-[174px] mt-2">
                <UploadButton filename="Search" imageSrc={searchButton} />
              </div> */}
              <button
                onClick={hanldeSubmit}
                // disabled={!searchQuery && !selectedFunc}
                className={`w-40 h-[45px] border-[2px] rounded-[8px] py-[10.5px] px-2 xl:px-[16px] flex justify-center items-center 
                  ${
                     "bg-[#1C468E] cursor-pointer border-[#1C468E]"
                  } mt-2`}
              >
                <img src={searchButton} alt="searchButton" />
                <span className="ml-1 text-[14px] md:text-base font-normal text-[#fff] lg:text-[16px] text-gilroy-medium ">
                  Search
                </span>
              </button>
              <div
                className="w-44 h-[40px] border-[1px] rounded-[8px] py-[10.5px] px-2 xl:px-[16px] border-[#1C468E] flex justify-center items-center mt-2 hover:cursor-pointer"
                onClick={() => {
                  setSelectedFuncs([]);
                  setRoleEditId(undefined);
                  setRoleName(undefined);
                  setModalOpenClose(true);
                  toggleModal(true);
                }}
              >
                <img src={addCircle} alt="icon" />
                <span className="ml-1 text-[14px] md:text-base font-normal text-[#1C468E] lg:text-[16px] text-gilroy-medium ">
                  Add Role
                </span>
              </div>
            </div>

            <div className="h-screen md:h-auto sm:h-auto overflow-x-hidden overflow-y-auto">
              <div className="mb-20">
                {loader ? (
                  <LoaderSpin />
                ) : (
                  <>
                    {roles?.length > 0 ? (
                      <ReactTable defaultData={roles} columns={columns} />
                    ) : (
                      <div className="flex justify-center items-center mt-5">
                        <p className="text-xl text-gilroy-mediumItalic ">
                          No Data Available
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="mt-10 absolute bottom-0">
              {roles.length > 0 && (
                <CustomPagination
                  currentPage={page}
                  setCurrentPage={setPage}
                  totalItems={total}
                  itemsPerPage={pageSize}
                  maxPageNumbersToShow={5}
                />
              )}
            </div>

            {modalOpenClose && (
              <AddNewRoleModal
                onClose={handleModal}
                functionalities={uamFunctionalities}
                roleId={roleEditId}
                selectedFuncs={selectedFuncs}
                roleName={roleName}
                isActive={isActive}
              />
            )}
          </div>
        }
      />
    </div>
  );
};

export default RoleCreation;
