import { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import ReactTable from "../../components/common/ReactTable";
import CmsTabs from "../../components/cms/CmsTabs";
import ActionButton from "../../components/common/ActionButton";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import { createColumnHelper } from "@tanstack/react-table";
import ContentsDiv from "../../components/cms/ContentsDiv";
import RegSignUpHead from "../../components/cms/RegSignUpHead";
import EditModal from "../../components/cms/EditModal";
import { useLocation } from "react-router";
import EditDocumentModel from "../../components/cms/EditDocument";
import LoaderSpin from "../../components/LoaderSpin";
import ManageFormFieldsForRegistration from "../../components/cms/ManageFormFieldsForRegistration";
import useCmsFormControlStore from "../../state/cmsFormControl";
import AddFormFieldModal from "../../components/cms/AddFormFieldModal";
import { axiosUAMInstance } from "../../utils/axios";
import AddFormFieldModalV2 from "../../components/cms/AddFormFieldModalV2";

type CmsRegister = {
  id: number;
  documentName: string;
  addToRegistration: boolean;
  addToProfile: boolean;
  required: boolean;
};

const columnHelper = createColumnHelper<CmsRegister>();

const CmsRegisterFormControl = () => {
  const formChanged = useCmsFormControlStore((state) => state.formFieldUpdated);
  const documentChanged = useCmsFormControlStore(
    (state) => state.documentFieldUpdated
  );
  const [data, setData] = useState<CmsRegister[]>();
  const [dataTotal, setDataTotal] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [singleDocument, setSingleDocument] = useState<object>();
  const [registerData, setRegisterData] = useState<[]>([]);
  const [registerDataca, setRegisterDataca] = useState<[]>([]);
  const [profileData, setProfileData] = useState<[]>([]);
  const [profileDataca, setProfileDataca] = useState<[]>([]);
  const [fileTypeData, setFileTypeData] = useState<[]>([]);
  const [fileTypeDataca, setFileTypeDataca] = useState<[]>([]);
  const [validations, setValidations] = useState<any[]>([]);
  const [validationsca, setValidationsca] = useState<any[]>([]);
  const [fieldTypes, setFieldTypes] = useState<any[]>([]);
  const [fieldTypesca, setFieldTypesca] = useState<any[]>([]);
  const [entitySectionsData, setentitySectionsData] = useState<[]>([]);
  const [entitySectionsDataca, setentitySectionsDataca] = useState<[]>([]);
  const [modalOpenClose, setModalOpenClose] = useState<boolean>(false);
  const [modalFieldForm, setmodalFieldForm] = useState<boolean>(false);
  const [editFormFieldData, setEditFormFieldData] = useState<any>(undefined);
  const [modalOpenCloseAdd, setModalOpenCloseAdd] = useState<boolean>(false);
  const [modalFormFieldOpenCloseAdd, setFormFieldModalOpenCloseAdd] =
    useState<boolean>(false);
  const [modalFormFieldOpenCloseAddv1, setFormFieldModalOpenCloseAddv1] =
    useState<boolean>(false);
  const [modalFormFieldData, setModalFormFieldData] = useState<any[]>([]);
  const [modalFormFieldDataca, setModalFormFieldDataca] = useState<any[]>([]);
  const [formFieldType, setFormFieldType] = useState<string>("");
  console.log({ formFieldType }, "formFieldType");
  const location = useLocation();
  console.log({ editFormFieldData }, "editFormFieldData");

  const registerId = location?.state?.id?.id || "";
  const entityName = location?.state?.id?.entityName || "";
  const shouldShowAddToRegistration = () => entityName !== "Deposit Taker";

  useEffect(() => {
    const getAllRegistrationFields = (registerId: string) => {
      setLoader(true);
      axiosUAMInstance
        .get(`/registration/field-data/${registerId}`)
        .then((response) => {
          setValidations(response?.data?.data?.validations);
          setFieldTypes(response?.data?.data?.fieldTypes);
          setentitySectionsData(response?.data?.data?.entitySections);
          setFileTypeData(response?.data?.data?.fileTypes);
          setProfileData(
            response?.data?.data?.formFields
              ?.filter((f: any) => f.addToProfile === true)
              ?.map((f: any, i: number) => ({
                sr_no: i + 1,
                tmp_value: "",
                ...f,
              }))
          );
          setRegisterData(
            response?.data?.data?.formFields
              ?.filter((f: any) => f.addToRegistration === true)
              ?.map((f: any, i: number) => ({
                sr_no: i + 1,
                tmp_value: "",
                ...f,
              }))
          );
          setModalFormFieldData(
            response?.data?.data?.formFields?.map((f: any, i: number) => ({
              sr_no: i + 1,
              tmp_value: "",
              ...f,
            }))
          );
          setLoader(false);
        })
        .catch((error: any) => {
          console.log(error);
          setLoader(false);
        });
    };

    getAllRegistrationFields(registerId);
  }, [formChanged]);
  useEffect(() => {
    const getAllRegistrationFields = (registerId: string) => {
      setLoader(true);
      axiosUAMInstance
        .get(
          `/registration/field-data/1?status=addToRegistration&creationBy=RGCA`
        )
        .then((response) => {
          setValidationsca(response?.data?.data?.validations);
          setFieldTypesca(response?.data?.data?.fieldTypes);
          setentitySectionsDataca(response?.data?.data?.entitySections);
          setFileTypeDataca(response?.data?.data?.fileTypes);
          setProfileDataca(
            response?.data?.data?.formFields
              ?.filter((f: any) => f.addToProfile === true)
              ?.map((f: any, i: number) => ({
                sr_no: i + 1,
                tmp_value: "",
                ...f,
              }))
          );
          setRegisterDataca(
            response?.data?.data?.formFields
              ?.filter((f: any) => f.addToRegistration === true)
              ?.map((f: any, i: number) => ({
                sr_no: i + 1,
                tmp_value: "",
                ...f,
              }))
          );
          setModalFormFieldDataca(
            response?.data?.data?.formFields?.map((f: any, i: number) => ({
              sr_no: i + 1,
              tmp_value: "",
              ...f,
            }))
          );
          setLoader(false);
        })
        .catch((error: any) => {
          console.log(error);
          setLoader(false);
        });
    };

    getAllRegistrationFields(registerId);
  }, [formChanged]);
  const editDocumentSubmit = () => {
    setLoader(true);
    axiosUAMInstance
      .get(`/registration/document-fields/${registerId}`)
      .then((response) => {
        const sortedData = response?.data?.data.sort(
          (a: any, b: any) => a.id - b.id
        );
        setData(sortedData);
        // console.log(sortedData.length)
        setDataTotal(sortedData.length);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    editDocumentSubmit();
  }, [registerId, documentChanged]);

  let count = 0;

  const columns = [
    columnHelper.accessor("id", {
      cell: () => {
        while (count <= dataTotal) {
          count++;
          return count;
        }
      },
      header: () => <span>Sr. No.</span>,
    }),
    columnHelper.accessor("documentName", {
      cell: (info) => info.renderValue(),
      header: () => <span>Entity Name</span>,
    }),
    ...(shouldShowAddToRegistration()
      ? [
          columnHelper.accessor("addToRegistration", {
            cell: (info) => {
              const value = info.getValue();
              const documentFieldId = info.row.original.id;
              const Status = info.row.original.addToRegistration;
              const keyword = info.column.id;
              console.log({ status: Status, keyword: keyword });
              const apicall = () => {
                axiosUAMInstance
                  .patch(`/registration/document-status`, {
                    documentFieldId,
                    status: !Status,
                    keyword,
                  })
                  .then((response) => {
                    console.log(response);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              };
              return <ToggleSwitch enabled={Status} apiCall={apicall} />;
            },
            header: () => <span>Add to Registration</span>,
          }),
        ]
      : []),

    columnHelper.accessor("addToProfile", {
      cell: (info) => {
        const value: boolean = info.getValue();
        const documentFieldId = info.row.original.id;
        const Status = info.row.original.addToRegistration;
        const keyword = info.column.id;

        const apicall = () => {
          axiosUAMInstance
            .patch(`/registration/document-status`, {
              documentFieldId: documentFieldId,
              status: !value,
              keyword,
            })
            .then((response) => {
              console.log(response);
            })
            .catch((err) => {
              console.log(err);
            });
        };
        return (
          <>
            <ToggleSwitch enabled={value} apiCall={apicall} />
          </>
        );
      },
      header: () => <span>Add to Profile</span>,
    }),

    columnHelper.accessor("required", {
      cell: (info) => {
        const documentFieldId = info.row.original.id;
        const Status = info.row.original.required;
        const keyword = info.column.id;

        const apicall = () => {
          axiosUAMInstance
            .patch(`/registration/document-status`, {
              documentFieldId: documentFieldId,
              status: !Status,
              keyword,
            })
            .then((response) => {
              console.log(response);
            })
            .catch((err) => {
              console.log(err);
            });
        };

        return (
          <>
            <ToggleSwitch enabled={Status} apiCall={apicall} />
          </>
        );
      },
      header: () => <span>Required</span>,
    }),

    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => {
        const value = info.getValue();

        return (
          <div className="flex flex-row justify-center">
            <div>
              <ActionButton
                variant="edit"
                onClick={() => {
                  setSingleDocument(info?.row?.original);
                  setModalOpenClose(true);
                }}
              />
            </div>
          </div>
        );
      },
      header: () => <span>Action</span>,
    }),
  ];

  const handleFormFieldModal = (tag: "signup" | "registration") => {
    // setModalFormFieldData(tag === "registration" ? registerData : profileData);
    setmodalFieldForm(true);
  };

  const handleModal = () => {
    setModalOpenClose(!modalOpenClose);
  };
  const handleModalAdd = () => {
    setModalOpenCloseAdd(false);
  };

  const handleOpenFormFieldAddModal = (type: string) => {
    setFormFieldType(type);
    setFormFieldModalOpenCloseAdd(true);
  };

  const handleTabOnClick = (data: any) => {
    setEditFormFieldData(data);
    setFormFieldModalOpenCloseAdd(true);
  };
  const handleTabOnClickV1 = (data: any) => {
    setEditFormFieldData(data);
    setFormFieldModalOpenCloseAddv1(true);
  };

  console.log({ entitySectionsData });

  return (
    <div>
      <Layout
        layout={
          <div className="relative">
            <div className="mt-4 ml-4 xl:ml-[40px]">
              <CmsTabs />
            </div>
            <div className="mt-[35px] mx-4 xl:mx-[40px] flex flex-col ">
              <div className="rounded-t-lg rounded-b-lg">
                <RegSignUpHead
                  buttonLabel2="Add"
                  buttonLabel="Edit"
                  heading="Signup form"
                  button2OnClick={() => handleOpenFormFieldAddModal("signup")}
                  onClick={() => handleFormFieldModal("signup")}
                  Data={registerData}
                />

                {loader ? (
                  <div className="">
                    <LoaderSpin />
                  </div>
                ) : (
                  <>
                    {registerData && registerData.length > 0 ? (
                      <ContentsDiv
                        tabsData={registerData || []}
                        handleTabOnClick={handleTabOnClick}
                      />
                    ) : (
                      <div>
                        <p className="text-lg text-center text-gilroy-regular font-normal">
                          Please add field
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="rounded-t-lg rounded-b-lg mt-4">
                <RegSignUpHead
                  buttonLabel2="Add"
                  buttonLabel="Edit"
                  heading="Registration / Update profile"
                  button2OnClick={() =>
                    handleOpenFormFieldAddModal("registration")
                  }
                  onClick={() => handleFormFieldModal("registration")}
                  Data={profileData}
                />

                {loader ? (
                  <div>
                    <LoaderSpin />
                  </div>
                ) : profileData && profileData.length > 0 ? (
                  <ContentsDiv
                    tabsData={profileData || []}
                    handleTabOnClick={handleTabOnClick}
                  />
                ) : (
                  <div>
                    <p className="text-lg text-center text-gilroy-regular font-normal">
                      Please add field
                    </p>
                  </div>
                )}
              </div>
              {entityName === "Deposit Taker" && (
                <div className="rounded-t-lg rounded-b-lg mt-4">
                  <RegSignUpHead
                    heading="Signup from Competent Authority / Regulator"
                    Data={registerDataca}
                  />

                  {loader ? (
                    <div>
                      <LoaderSpin />
                    </div>
                  ) : registerDataca && registerDataca.length > 0 ? (
                    <ContentsDiv
                      tabsData={registerDataca || []}
                      handleTabOnClick={handleTabOnClickV1}
                    />
                  ) : (
                    <div>
                      <p className="text-lg text-center text-gilroy-regular font-normal">
                        Please add field
                      </p>
                    </div>
                  )}
                </div>
              )}

              <div className="rounded-t-lg rounded-b-lg mt-4">
                <RegSignUpHead
                  buttonLabel="Add"
                  heading="Documents"
                  onClick={setModalOpenCloseAdd}
                />
              </div>

              {loader ? (
                <div className="mt-10">
                  <LoaderSpin />
                </div>
              ) : (
                <div className="mb-4 mt-[27px]">
                  {data && data?.length > 0 && (
                    <ReactTable defaultData={data} columns={columns} />
                  )}
                </div>
              )}
            </div>

            {data && data?.length > 0 && modalOpenClose && (
              <EditDocumentModel
                handleOpenClose={handleModal}
                heading="Edit Document"
                singleDocument={singleDocument}
                registerId={registerId}
                fileTypeData={fileTypeData}
                entitySectionsData={entitySectionsData}
                apiCall={editDocumentSubmit}
              />
            )}
            {modalFieldForm && (
              <ManageFormFieldsForRegistration
                entitySections={entitySectionsData}
                handleOpenClose={() => setmodalFieldForm(false)}
                defaultData={modalFormFieldData}
              />
            )}

            {modalOpenCloseAdd && (
              <EditModal
                handleOpenClose={handleModalAdd}
                heading="Add Document"
                fileTypeData={fileTypeData}
                entitySectionsData={entitySectionsData}
              />
            )}
            {modalFormFieldOpenCloseAdd && (
              <AddFormFieldModal
                editData={editFormFieldData}
                handleOpenClose={() => {
                  setFormFieldModalOpenCloseAdd(false);
                  setEditFormFieldData(undefined);
                }}
                heading="Add Field"
                entitySectionsData={entitySectionsData}
                validations={validations}
                fieldTypes={fieldTypes}
                type={formFieldType}
              />
            )}
            {modalFormFieldOpenCloseAddv1 && (
              <AddFormFieldModalV2
                editData={editFormFieldData}
                handleOpenClose={() => {
                  setFormFieldModalOpenCloseAddv1(false);
                  setEditFormFieldData(undefined);
                }}
                heading="Add Field"
                entitySectionsData={entitySectionsDataca}
                validations={validationsca}
                fieldTypes={fieldTypesca}
                type={formFieldType}
              />
            )}
          </div>
        }
      />
    </div>
  );
};

export default CmsRegisterFormControl;
