import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import TaskTabs from "../../components/MyTasks/TaskTabs";
import FolderIcon from "../../assets/images/folder-open.svg";
import Button from "../../components/form/Button";
import BackArrow from "../../assets/images/BackArrow.svg";
import RegularModelPopup from "../../components/MyTasks/RegulatorModelPopup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDepositTakerRegistrationStore } from "../../state/registrationStore";
import { getMimeTypeFromArrayBuffer } from "../../utils/commonFunction";
import Swal from "sweetalert2";
import ApprovePopupRegister from "../../components/MyTasks/ApprovePopupRegister";
import moment from "moment";
import LoaderSpin from "../../components/LoaderSpin";
import { axiosUAMInstance } from "../../utils/axios";

const MyTaskDesignatedCourtForm = () => {
  const location = useLocation();
  const designatedCourtId = location.state?.designatedCourtId;
  const [loader, setLoader] = useState<boolean>(false);
  const [viewLoaders, setViewLoaders] = useState<Record<number, boolean>>({});
  const { setAllFormData, setAllDocumentData, allFormData, documentData } =
    useDepositTakerRegistrationStore((state) => state);
  // console.log({ allFormData, documentData });
  const [approveModel, setApproveModel] = useState<boolean>(false);
  const approverRelation =
    location.state?.approverRelation?.firstName +
    " " +
    location.state?.approverRelation?.lastName;
  const status = location?.state?.status;
  const approveTimeStamp = location?.state?.approveTimeStamp;
  const checkerStamp = location?.state?.checkerStamp;
  const createdAt = location?.state?.createdAt;
  const page = location?.state?.page;
  const fetchFormFields = () => {
    setLoader(true);
    axiosUAMInstance
      .get(`/registration/field-data/4?status=addToProfile`)
      .then(async (response) => {
        // console.log(response?.data, "response");

        if (response?.data?.success) {
          let dcData: any = [];
          try {
            let designatedCourt = await axiosUAMInstance.get(
              `/designated-court/${designatedCourtId}`
            );
            dcData =
              designatedCourt.data.data.designatedCourt
                ?.designatedCourtFormData;
          } catch (error) {
            console.log("Error");
          }
          // console.log(dtData, "respnse--------------");
          let modifiedFormFields = response.data.data?.formFields?.map(
            (o: any) => ({
              ...o,
              userInput: dcData
                ? dcData?.find((data: any) => data?.fieldId === o?.id)?.value
                : "",
              error: "",
            })
          );

          let modifiedFileFields =
            response?.data?.data?.registrationDocumentFields?.map((o: any) => ({
              ...o,
              file: dcData
                ? dcData?.find((data: any) => data?.fieldId === o?.id)?.value
                : "",
              error: "",
              fileName: dcData
                ? dcData?.find((data: any) => data?.fieldId === o?.id)?.value
                : "",
              uploadFileId: dcData
                ? dcData?.find((data: any) => data?.fieldId === o?.id)?.value
                : "",
            }));

          let obj = {
            ...response?.data?.data,
            formFields: { form_fields: modifiedFormFields },
          };
          // console.log(obj, "obj-----");
          setAllFormData(obj);
          setAllDocumentData(modifiedFileFields);
        } else {
          throw new Error("Error getting data, Please try later!");
        }

        setLoader(false);
      })
      .catch((error: any) => {
        console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchFormFields();
  }, []);

  const getFileDatafromBuffer = async (arrayBuffer: any) => {
    const buffer = new Uint8Array(arrayBuffer);
    const type = await getMimeTypeFromArrayBuffer(buffer);
    const blob = new Blob([buffer], { type: type ?? "" });
    const imageUrl = URL.createObjectURL(blob);
    window.open(imageUrl, "_blank", "noopener");
  };

  const handleOnClikcView = async (uploadFileId: any, index: number) => {
    try {
      // setLoader(true);
      setViewLoaders((prev) => ({ ...prev, [index]: true }));
      const response = await axiosUAMInstance.get(
        `/openkm/get/${uploadFileId}`
      );
      const data = await response.data;
      if (data?.status === "INTERNAL_SERVER_ERROR") {
        Swal.fire({
          icon: "error",
          title: "Internal Server Error",
          text: "Unable to Open File",
        });
        // setLoader(false);
        setViewLoaders((prev) => ({ ...prev, [index]: false }));
        return;
      }
      const arrayBuffer = data?.data?.data;

      await getFileDatafromBuffer(arrayBuffer);
      // console.log({buffer, type, blob, url});
      // setViewLoader(false);
      // await fetchFormFields();
      setViewLoaders((prev) => ({ ...prev, [index]: false }));
    } catch (error) {
      console.log({ error });
      // setViewLoader(false);
      setViewLoaders((prev) => ({ ...prev, [index]: false }));
    }
  };

  const [courtModel, setCourtModel] = useState<boolean>(false);

  const handlerCourt = () => {
    setCourtModel(true);
  };

  const CloseCourtModel = () => {
    setCourtModel(false);
  };
  const handleApproveModel = () => {
    setApproveModel(true);
  };
  const navigate = useNavigate();
  const handleStatus = (currentStatus: string) => {
    switch (currentStatus) {
      case "TRANSIT":
        return true;
      case "MOD_TRANSIT":
        return true;
      default:
        return false;
    }
  };
  const handleBack = () => {
    const currentPage = page || 1;
    navigate("/mytask/designatedcourt", { state: { currentPage } });
  };

  return (
    <div>
      <Layout
        layout={
          <div className="relative mx-4 xl:ml-[40px]">
            <div className="mt-6 ">
              <TaskTabs />
            </div>
            <div>
              <>
                {loader ? (
                  <LoaderSpin />
                ) : (
                  <>
                    <div className="container mx-auto">
                      {/* <div id="reviewContent">
                    <h1 className=" text-gilroy-bold text-[#24222B] text-2xl font-bold  my-7">
                      Lorem ipsum
                    </h1>
                    {sections.map((section, index) => (
                      <div className="mb-[16px]" key={index}>
                        <div className="rounded-t-lg bg-[#EEF7EB] flex justify-between h-[57px] text-gilroy-bold">
                          <p className="lg:w-[152px] ml-[16px] mt-[16px] text-[16px] lg:text-[20px] pb-2 text-nowrap">
                            {section.title}
                          </p>
                          <button className="text-[#385723] text-[16px] lg:text-[20px] mr-[13px] font-normal ">
                            {section.buttonText}
                          </button>
                        </div>

                        <div className="shadow-sm p-5 rounded-md ">
                          <div className="flex flex-col justify-between w-full sm:flex-row gap-y-[16px]">
                            <div className="  w-full sm:border-r-[0.5px] border-r-[#385723] border-opacity-20 grid gap-y-[16px]">
                              {section.fieldsLeft.map((field, idx) => (
                                <div
                                  className="sm:mr-[48px] flex justify-between "
                                  key={idx}
                                >
                                  <div className="opacity-60">
                                    {field.label}
                                    <span className="text-[#ff0000]">*</span>
                                  </div>
                                  <div>{field.value}</div>
                                </div>
                              ))}
                            </div>

                            <div className="w-full grid gap-y-[16px]">
                              {section.fieldsRight.map((field, idx) => (
                                <div
                                  className="sm:ml-[48px] flex justify-between"
                                  key={idx}
                                >
                                  <div className="opacity-60">
                                    {field.label}
                                    <span className="text-[#ff0000]">*</span>
                                  </div>
                                  <div>{field.value}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div> */}
                      <div id="reviewContent">
                        {handleStatus(status) && (
                          <p>
                            This record has been approved by {approverRelation}
                            &nbsp;
                            {checkerStamp &&
                              `on ${moment(checkerStamp).format("DD/MM/YYYY")}`}
                          </p>
                        )}
                        <h1 className=" text-gilroy-bold text-[#24222B] text-2xl font-bold  my-7"></h1>
                        {allFormData?.entitySections
                          ?.filter(
                            (s: any) => s?.sectionName !== "Upload Documents"
                          )
                          .map((section: any, index: number) => (
                            <div className="mb-[16px]" key={index}>
                              <div className="rounded-t-lg bg-[#e7f0ff] flex justify-between h-[57px] text-gilroy-bold">
                                <p className="lg:w-[152px] ml-[16px] mt-[16px] text-[16px] lg:text-[20px] pb-2 text-nowrap">
                                  {section?.sectionName === "Nodal Details"
                                    ? "Nodal Officer Details"
                                    : section?.sectionName}
                                </p>
                                <button className="text-[#385723] text-[16px] lg:text-[20px] mr-[13px] font-normal ">
                                  {/* {section.buttonText} */}
                                </button>
                              </div>
                              <div className="shadow-sm p-5 rounded-md">
                                <div className="flex flex-col justify-between w-full sm:flex-row gap-y-[16px]">
                                  <div className="w-full grid grid-cols-2">
                                    {allFormData?.formFields?.form_fields
                                      ?.filter(
                                        (f: any) => f?.sectionId === section?.id
                                      )
                                      ?.map((field: any, idx: number) => (
                                        <div
                                          className={`${
                                            idx % 2 === 0
                                              ? "pr-4 pt-2 sm:border-r-[0.5px] border-r-[#385723] border-opacity-20"
                                              : "pl-4 pt-2"
                                          } flex justify-between`}
                                          key={idx}
                                        >
                                          <div className="opacity-60">
                                            {field.label}
                                            {/* <span className="text-[#ff0000]">
                                              *
                                            </span> */}
                                          </div>
                                          <div className="break-all">
                                            {field.label === "DSC3 Certificate"
                                              ? "DSC Certification "
                                              : field.userInput}
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>

                              {/* <div className="shadow-sm p-5 rounded-md">
                                <div className="flex flex-col justify-between w-full sm:flex-row gap-y-4">
                                  {allFormData?.formFields?.form_fields?.filter(
                                    (f: any) => f?.sectionId === section?.id
                                  ).length > 2 ? (
                                    <>
                                      <div className="w-full sm:w-1/2 sm:border-r-2 border-r-[#385723] border-opacity-20 grid gap-y-4 pr-12">
                                        {allFormData?.formFields?.form_fields
                                          ?.filter(
                                            (f: any, idx: number) =>
                                              f?.sectionId === section?.id &&
                                              idx % 2 === 0
                                          ) // Filter for left column
                                          .map((field: any, idx: number) => (
                                            <div
                                              className="flex justify-between"
                                              key={idx}
                                            >
                                              <div className="opacity-60">
                                                {field.label}
                                                {field.required && (
                                                  <span className="text-red-500">
                                                    *
                                                  </span>
                                                )}
                                              </div>
                                              <div className="break-all">
                                                {field.label ===
                                                "DSC3 Certificate"
                                                  ? "DSC Certification"
                                                  : field.userInput}
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                      <div className="w-full sm:w-1/2 grid gap-y-4 pl-12">
                                        {allFormData?.formFields?.form_fields
                                          ?.filter(
                                            (f: any, idx: number) =>
                                              f?.sectionId === section?.id &&
                                              idx % 2 !== 0
                                          ) // Filter for right column
                                          .map((field: any, idx: number) => (
                                            <div
                                              className="flex justify-between"
                                              key={idx}
                                            >
                                              <div className="opacity-60">
                                                {field.label}
                                                {field.required && (
                                                  <span className="text-red-500">
                                                    *
                                                  </span>
                                                )}
                                              </div>
                                              <div className="break-all">
                                                {field.label ===
                                                "DSC3 Certificate"
                                                  ? "DSC Certification"
                                                  : field.userInput}
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    </>
                                  ) : (
                                    <div className="w-full grid gap-y-4">
                                      {allFormData?.formFields?.form_fields
                                        ?.filter(
                                          (f: any) =>
                                            f?.sectionId === section?.id
                                        )
                                        .map((field: any, idx: any) => (
                                          <div
                                            className="flex justify-between"
                                            key={idx}
                                          >
                                            <div className="opacity-60">
                                              {field.label}
                                              {field.required && (
                                                <span className="text-red-500">
                                                  *
                                                </span>
                                              )}
                                            </div>
                                            <div className="break-all">
                                              {field.label ===
                                              "DSC3 Certificate"
                                                ? "DSC Certification"
                                                : field.userInput}
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                </div>
                              </div> */}
                            </div>
                          ))}
                      </div>

                      <div>
                        <div className="rounded-t-lg bg-[#e7f0ff] flex justify-between h-[57px] text-gilroy-bold mb-4">
                          <p className="lg:w-[152px] ml-[16px] mt-[16px] text-xl lg:text-[20px] pb-2 text-nowrap">
                            Upload Documents
                          </p>
                        </div>

                        {documentData?.map((uploadItem: any, index: number) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="rounded-t-lg bg-[#e7f0ff] flex justify-between items-center h-16 text-gilroy-bold mb-4"
                              >
                                <div className="flex p-7 space-x-2 justify-center items-center">
                                  <div className="">
                                    <img
                                      src={FolderIcon}
                                      alt={FolderIcon}
                                      className="w-6"
                                    />
                                  </div>
                                  <div className="">
                                    <h1 className="text-sm font-normal text-gilroy-medium text-[#1D1D1B]">
                                      {uploadItem?.documentName}
                                    </h1>
                                    {/* <p className="text-base font-normal text-gilroy-medium text-gray-400">
                                      Document.pdf
                                    </p> */}
                                  </div>
                                </div>
                                <div className="mr-3">
                                  <Button
                                    // disabled={
                                    //   uploadItem.uploadFileId &&
                                    //   uploadItem.file === undefined
                                    //     ? true
                                    //     : false
                                    // }
                                    disabled={
                                      uploadItem.uploadFileId === undefined &&
                                      uploadItem.file === undefined
                                    }
                                    loader={viewLoaders[index]}
                                    onClick={() =>
                                      handleOnClikcView(
                                        uploadItem?.uploadFileId,
                                        index
                                      )
                                    }
                                    label="View"
                                    type="button"
                                    width="100px"
                                  />
                                </div>
                              </div>
                            </>
                          );
                        })}
                        {/* <div className="rounded-t-lg bg-[#EEF7EB] flex justify-between items-center h-16 text-gilroy-bold mb-4">
                      <div className="flex p-7 space-x-2 ">
                        <div className="mt-2">
                          <img
                            src={FolderIcon}
                            alt={FolderIcon}
                            className="w-6"
                          />
                        </div>
                        <div className="">
                          <h1 className="text-sm font-normal text-gilroy-medium text-[#1D1D1B]">
                            Document Uploaded
                          </h1>
                          <p className="text-base font-normal text-gilroy-medium text-gray-400">
                            Document.pdf
                          </p>
                        </div>
                      </div>
                      <div className="mr-3">
                        <Button label="View" type="button" width="100px" />
                      </div>
                    </div> */}
                      </div>
                      {/* <div>
                    {sections1.map((section, index) => (
                      <div className="mb-[16px]" key={index}>
                        <div className="rounded-t-lg bg-[#EEF7EB] flex justify-between h-[57px] text-gilroy-bold">
                          <p className="lg:w-[152px] ml-[16px] mt-[16px] text-[16px] lg:text-[20px] pb-2 text-nowrap">
                            {section.title}
                          </p>
                          <button className="text-[#385723] text-[16px] lg:text-[20px] mr-[13px] font-normal ">
                            {section.buttonText}
                          </button>
                        </div>

                        <div className="shadow-sm p-5 rounded-md ">
                          <div className="flex flex-col justify-between w-full sm:flex-row gap-y-[16px]">
                            <div className="  w-full sm:border-r-[0.5px] border-r-[#385723] border-opacity-20 grid gap-y-[16px]">
                              {section.fieldsLeft.map((field, idx) => (
                                <div
                                  className="sm:mr-[48px] flex justify-between "
                                  key={idx}
                                >
                                  <div className="opacity-60">
                                    {field.label}
                                    <span className="text-[#ff0000]">*</span>
                                  </div>
                                  <div>{field.value}</div>
                                </div>
                              ))}
                            </div>

                            <div className="w-full grid gap-y-[16px]">
                              {section.fieldsRight.map((field, idx) => (
                                <div
                                  className="sm:ml-[48px] flex justify-between"
                                  key={idx}
                                >
                                  <div className="opacity-60">
                                    {field.label}
                                    <span className="text-[#ff0000]">*</span>
                                  </div>
                                  <div>{field.value}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div> */}
                    </div>
                  </>
                )}

                <div>
                  <span className="text-sm font-normal text-gilroy-medium ">
                    Date :{" "}
                    {createdAt &&
                      ` ${moment(createdAt).format("DD-MM-YYYY HH:mm")}`}
                  </span>
                </div>

                <div className="my-11 flex flex-col md:flex-row justify-between items-center">
                  <div
                    className="flex items-center cursor-pointer space-x-2 mb-3 md:mb-0"
                    // onClick={() => navigate(-1)}
                    onClick={handleBack}
                  >
                    <img src={BackArrow} alt={BackArrow} />
                    <p className="text-sm font-normal text-gilroy-regular">
                      Back
                    </p>
                  </div>
                  <div className="flex flex-wrap justify-center items-center space-x-3 md:w-auto">
                    <div className="mb-3 md:mb-0">
                      <Button
                        type="button"
                        label="Return"
                        width="100px"
                        textColor="#385723"
                        borderColor="#385723"
                        backgroundColor="white"
                        onClick={handlerCourt}
                      />
                    </div>
                    <div className="mb-3 md:mb-0">
                      <Button
                        type="button"
                        label="Approve"
                        width="100px"
                        onClick={handleApproveModel}
                      />
                    </div>
                  </div>
                </div>

                {courtModel && (
                  <RegularModelPopup
                    ReturnId={designatedCourtId}
                    onClose={CloseCourtModel}
                    onSave={() => {}}
                  />
                )}
                {approveModel && (
                  <ApprovePopupRegister
                    Id={designatedCourtId}
                    onClose={() => {
                      setApproveModel(false);
                    }}
                    onSave={() => {}}
                  />
                )}
              </>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default MyTaskDesignatedCourtForm;
