import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import { createColumnHelper } from "@tanstack/table-core";
import ActionButton from "../../components/common/ActionButton";
import ReactTable from "../../components/common/ReactTable";
import CustomPagination from "../../components/cms/Pagination/CustumPagination";
import SubTabs from "../../components/cms/SubTabs";
import InputFields from "../../components/form/InputField";
import FileUploadOne from "../../components/common/FileUploadOne";
import Add from "../../assets/images/new_images/add-circle.png";
import UploadIcon from "../../assets/images/UploadIcon.png";
import { useNavigate } from "react-router";
import useMasterEntitiesStore from "../../state/masterEntitiesStates";
import { axiosUAMInstance } from "../../utils/axios";
import LoaderSpin from "../../components/LoaderSpin";
type TableType = {
  sno: number;
  masterEntityId: string;
  name: string;
  address1: string;
  address2: string;
  stateId: string;
  districtId: string;
  jurisdictionId: string;
};

const columnHelper = createColumnHelper<TableType>();
// const columns = [
//   columnHelper.accessor("masterEntityId", {
//     cell: (info) => info.renderValue(),
//     header: () => <span>Sr. No.</span>,
//   }),
//   columnHelper.accessor("name", {
//     cell: (info) => info.renderValue(),
//     header: () => <span>Name</span>,
//   }),
//   columnHelper.accessor("address1", {
//     cell: (info) => info.renderValue(),
//     header: () => <span>Address Line 1</span>,
//   }),
//   columnHelper.accessor("address2", {
//     cell: (info) => info.renderValue(),
//     header: () => <span>Address Line 2</span>,
//   }),
//   {
//     accessorFn: (row: any) => row,
//     id: "State",
//     cell: (info: any) => {
//       const state = info.row.original.stateId;
//       return <span>{state}</span>;
//     },
//     header: () => <span>State</span>,
//   },
//   {
//     accessorFn: (row: any) => row,
//     id: "State",
//     cell: (info: any) => {
//       const state = info.row.original.districtId;
//       return <span>{state}</span>;
//     },
//     header: () => <span>District</span>,
//   },
// ];

const CmsMasterdesignatedcourt = () => {
  const { formSubmitted, handleFormSubmit } = useMasterEntitiesStore(
    (state) => state
  );
  const searching = useMasterEntitiesStore((state) => state.searching);
  const setSearchingOn = useMasterEntitiesStore(
    (state) => state.handleSearchInputChange
  );
  const setSearchingOff = useMasterEntitiesStore(
    (state) => state.setSearchingFalse
  );
  const [dcData, setdcData] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState<boolean>(false);

  let count: number;
  const serialNoGen = (page: number) => {
    count = (page - 1) * 10;
  };
  serialNoGen(page);

  const columns = [
    columnHelper.accessor("sno", {
      cell: () => {
        while (count <= total) {
          count++;
          return count;
        }
      },
      header: () => <span>S.No.</span>,
    }),
    columnHelper.accessor("masterEntityId", {
      cell: (info) => info.renderValue(),
      header: () => <span>Entity Id</span>,
    }),
    columnHelper.accessor("name", {
      cell: (info) => info.renderValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("address1", {
      cell: (info) => info.renderValue(),
      header: () => <span>Address Line 1</span>,
    }),
    columnHelper.accessor("address2", {
      cell: (info) => info.renderValue(),
      header: () => <span>Address Line 2</span>,
    }),
    {
      accessorFn: (row: any) => row,
      id: "State",
      cell: (info: any) => {
        const state = info.row.original.stateId;
        return <span>{state}</span>;
      },
      header: () => <span>State</span>,
    },
    {
      accessorFn: (row: any) => row,
      id: "State",
      cell: (info: any) => {
        const state = info.row.original.districtId;
        return <span>{state}</span>;
      },
      header: () => <span>District</span>,
    },
    // {
    //   accessorFn: (row: any) => row,
    //   id: "State",
    //   cell: (info: any) => {
    //     const state = info.row.original.State.name;
    //     return <span>{state}</span>;
    //   },
    //   header: () => <span>State</span>,
    // },
    // {
    //   accessorFn: (row: any) => row,
    //   id: "State",
    //   cell: (info: any) => {
    //     const state = info.row.original.District.name;
    //     return <span>{state}</span>;
    //   },
    //   header: () => <span>District</span>,
    // },
  ];

  const apiCall = () => {
    setLoader(true);
    axiosUAMInstance
      .get(`/master-entities/DC?page=${page}&pagesize=10&searchtext=${search}`)
      .then((response: any) => {
        if (response.data.success) {
          setdcData(response?.data?.data?.data);
          console.log(response?.data?.data);
          setTotal(response?.data?.data?.total);
        }

        setLoader(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoader(false);
      });
  };

  const handleSearchInput = (e: any) => {
    setSearchingOn();
    const { value } = e.target;
    setSearch(value);
  };

  useEffect(() => {
    apiCall();
  }, [page]);

  useEffect(() => {
    if (formSubmitted === true) {
      apiCall();
    }
  }, [formSubmitted]);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/cms/master/designatedcourt/form");
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (searching && search.trim() !== "") {
        setdcData([]);
      }
      if (searching) {
        apiCall();
      }
    }, 1500);

    return () => {
      clearTimeout(timerId);
      setTimeout(() => {
        setSearchingOff();
      }, 2000);
    };
  }, [search]);

  console.log(formSubmitted);

  return (
    <Layout
      layout={
        <div
          className="md:px-8 px-1 pb-[44px] relative "
          style={{ minHeight: "calc(100vh - 110px)" }}
        >
          <div className="mt-4">
            <CmsTabs />
          </div>
          <div className="my-6">
            <SubTabs />
          </div>
          <div className="">
            <div className=" flex  space-x-2  items-center flex-wrap">
              <div>
                <div className="mb-2">
                  <label
                    htmlFor="Country Search"
                    className="text-base font-normal text-gilroy-medium "
                  >
                    Competent Authority Search
                  </label>
                </div>

                <InputFields
                  height="40px"
                  width="630px"
                  padding="10px"
                  placeholder="Search by Unique ID/name"
                  onChange={handleSearchInput}
                />
              </div>
              <div className=" flex items-center mt-5 flex-col md:flex-col lg:flex-row sm:flex-row">
                {/* <div className=" w-44">
                  <FileUploadOne filename="Upload" icon={UploadIcon} />
                </div> */}
                <div className="mt-2">
                  <button
                    className="p-3 text-[#1c468e]   h-10 rounded-lg border border-[#1c468e] text-base text-gilroy-medium flex  items-center justify-center"
                    onClick={handleNavigate}
                  >
                    <img src={Add} alt="Add" className="mr-2 " />
                    <span className=" ">Add Designated Court</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="mb-20">
              {loader ? (
                <LoaderSpin />
              ) : (
                <>
                  {dcData?.length > 0 ? (
                    <ReactTable defaultData={dcData} columns={columns} />
                  ) : (
                    <div className="flex justify-center items-center mt-5">
                      <p className="text-xl text-gilroy-mediumItalic ">
                        No data available
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="mt-10 absolute bottom-0 ">
            {dcData.length > 0 && (
              <CustomPagination
                bottomLine={true}
                currentPage={page}
                setCurrentPage={setPage}
                totalItems={total}
                itemsPerPage={5}
                maxPageNumbersToShow={5}
              />
            )}
          </div>
        </div>
      }
    />
  );
};

export default CmsMasterdesignatedcourt;
