import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import add from "../../assets/images/add.svg";
import TextArea from "../common/TextArea";
import SignUpFormItems from "./SignUpFormItems";
import ButtonComp from "./ButtonComp";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import Swal from "sweetalert2";
import { axiosUAMInstance } from "../../utils/axios";

const style = {
  position: "absolute" as "absolute",
  top: "60%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

interface Props {
  onClose: () => void;
  data: any;
}
const WORD_LIMIT = 500;

const EditRegistrationOtpModal: React.FC<Props> = ({ onClose, data }) => {
  // const [text, setText] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [msg, setMsg] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [wordCountError, setWordCountError] = useState<boolean>(false); // Word count validation state
  const [emptyMessageError, setEmptyMessageError] = useState<boolean>(false);

  useEffect(() => {
    getApiCall();
  }, []);

  const getApiCall = () => {
    axiosUAMInstance
      .get(`/template/list/${data?.action}/${data?.id}`)
      .then((response) => {
        console.log(response?.data?.data);
        setTags(response?.data?.data?.applicable_tags);
        setMsg(response?.data?.data?.message_body);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateApiCall = () => {
    setLoader(true);
    axiosUAMInstance
      .put(`/template/update/${data?.id}`, {
        message: msg,
      })
      .then((response) => {
        console.log(response);
        Swal.fire({
          title: `Data updated successfully`,
          icon: "success",
        });
        setLoader(false);
        onClose();
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        Swal.fire({
          title: "Something went wrong! Please try again",
          icon: "error",
        });
      });
  };

  // const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
  //   setMsg(event.target.value);
  // };
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const inputText = event.target.value;
    setMsg(inputText);

    // Word count validation
    const wordCount = countWords(inputText);
    if (wordCount > WORD_LIMIT) {
      setWordCountError(true);
    } else {
      setWordCountError(false);
    }

    // Check if message is empty
    if (inputText.trim() === "") {
      setEmptyMessageError(true);
    } else {
      setEmptyMessageError(false);
    }
  };

  // const countWords = (text: string) => {
  //   const trimmedText = text.trim();
  //   const words = trimmedText.split(/\s+/);
  //   return words.length;
  // };
  const countWords = (text: string) => {
    if (!text.trim()) return 0;
    const words = text.trim().split(/\s+/);
    return words.length;
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Check for validation errors
    if (wordCountError || emptyMessageError || !msg.trim()) {
      if (!msg.trim()) {
        setEmptyMessageError(true);
      }
      return;
    }
    updateApiCall();
  };

  const handleClose = () => {
    onClose();
  };
  const handleTagClick = (tag: string) => {
    // Append tag to the message text
    setMsg((prevMsg) => prevMsg + " " + tag);
  };

  return (
    <>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="m-[16px] md:m-[0px] w-[300px] md:w-[500px] rounded-2xl p-[8px] text-gilroy-medium pb-[30px] shadow-xl backdrop-blur-lg bg-white">
            <div
              className="flex flex-row justify-end mb-[12px] cursor-pointer"
              onClick={handleClose}
            >
              <img src={add} className="w-6 h-6" alt="icon" />
            </div>
            <h1 className="mb-[24px] text-center text-black text-[24px] font-normal">
              Entity Registration OTP
            </h1>
            <form onSubmit={handleFormSubmit}>
              <div className="md:px-[32px] px-[16px]">
                <label
                  htmlFor="bodyText"
                  className=" text-black text-[16px] font-normal  mb-[4px] text-gilroy-medium "
                >
                  Body Text
                </label>
                <TextArea
                  value={msg}
                  mdWidth="440px"
                  id="bodyText"
                  onChange={handleChange}
                  placeholder="Type your reason here"
                  className="text-[16px] h-[90px] border  border-[#E6E6E6] rounded-[16px] w-full mt-[4px] p-[16px] focus:outline-none focus:ring-1 focus:ring-gray-100 textarea-component"
                />
                {emptyMessageError && (
                  <p className="text-red-500">Message cannot be empty.</p>
                )}
                {wordCountError && (
                  <p className="text-red-500">
                    Word limit exceeded. Please reduce your input to{" "}
                    {WORD_LIMIT} words or fewer.
                  </p>
                )}
                <div className=" flex justify-between">
                  <p className="mt-[22px] text-black text-base font-normal">
                    Applicable Tags:
                  </p>
                  <p className="mt-[21px] text-right text-black text-opacity-40 text-xs font-normal leading-[14px]">
                    {countWords(msg)}/{WORD_LIMIT}
                  </p>
                </div>

                <div className="my-[16px] justify-start items-start gap-2 flex flex-wrap pb-2">
                  {tags.map((menuItem, index) => (
                    <SignUpFormItems
                      key={index}
                      item={menuItem}
                      onClick={() => handleTagClick(menuItem)}
                    />
                  ))}
                </div>

                <hr className="w-full bg-[#E6E6E6] mb-[24px]"></hr>
                <ButtonComp onClose={handleClose} loader={loader} />
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default EditRegistrationOtpModal;
