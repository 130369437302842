import React, { useState, useEffect, useCallback, useRef } from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import { createColumnHelper } from "@tanstack/table-core";
import ActionButton from "../../components/common/ActionButton";
import ReactTable from "../../components/common/ReactTable";
import CustomPagination from "../../components/cms/Pagination/CustumPagination";
import SubTabs from "../../components/cms/SubTabs";
import InputFields from "../../components/form/InputField";
import FileUploadOne from "../../components/common/FileUploadOne";
import Add from "../../assets/images/add-circle.svg";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import DeleteIcon from "../../assets/images/delete.svg";
import { useNavigate } from "react-router";
import DirectBox from "../../assets/images/send.png";
import { axiosUAMInstance } from "../../utils/axios";
import LoaderSpin from "../../components/LoaderSpin";
import FailedModal from "../../components/common/FailedModal";
import UploadLanguage from "../../components/common/UploadLanguages";
import EditLanguage from "../../components/common/EditLanguage";
import FileUpload from "../../components/UserManagement/FileUpload";
type TableType = {
  id: string;
  // languages: string;
  displayLabel: string;
  isRTL: boolean;
  // Template: string;
  // Upload: string;
  // Action: boolean;
  languageName: string;
  isActive: boolean;
};

const columnHelper = createColumnHelper();

// const defaultData: TableType[] = [
//   {
//     sno: "01",
//     languages: "English",
//     Template: "ABC.xls",
//     Upload: "ABC.xls",
//     Action: true,
//   },
//   {
//     sno: "02",
//     languages: "English",
//     Template: "ABC.xls",
//     Upload: "ABC.xls",
//     Action: true,
//   },
//   {
//     sno: "03",
//     languages: "English",
//     Template: "ABC.xls",
//     Upload: "ABC.xls",
//     Action: true,
//   },
//   {
//     sno: "04",
//     languages: "English",
//     Template: "ABC.xls",
//     Upload: "",
//     Action: true,
//   },
//   {
//     sno: "05",
//     languages: "English",
//     Template: "ABC.xls",
//     Upload: "",
//     Action: true,
//   },
// ];

const CsmMasterLanguage = () => {
  const [loader, setLoader] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState(0);
  const [languageData, setLanguageData] = useState([]);
  const [searchText, setSearchText] = useState<string>("");
  const [showFailed, setShowFailed] = useState(false);
  const [uuidId, setUuidId] = useState<string>("");
  const [addLanguage, setAddLanguage] = useState(false);
  const [editLanguage, setEditLanguage] = useState(false);
  const [EditData, setEditData] = useState({});
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<"success" | "error">(
    "success"
  );

  const apiCallLanguage = useCallback(() => {
    setLoader(true);
    axiosUAMInstance
      .get("/language/get-all", {
        params: { page, pageSize, searchtext: searchText },
      })
      .then((res) => {
        // console.log(res, "res");
        if (res.status === 200) {
          setLanguageData(res?.data?.data?.languages);
          setTotal(res?.data?.data?.totalCount);
        }
        setLoader(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoader(false);
      });
  }, [page, pageSize, searchText]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      apiCallLanguage();
    }, 400);

    return () => clearTimeout(delayDebounce);
  }, [apiCallLanguage]);
  let count: number;
  const serialNoGen = (page: number) => {
    count = (page - 1) * 10;
  };
  serialNoGen(page);

  const closeFailedModel = () => {
    setShowFailed(false);
  };
  const showFailedModal = (id: string) => {
    setUuidId(id);
    setShowFailed(true);
  };
  const deleteLanguage = () => {
    setLoader(true);
    axiosUAMInstance
      .delete(`/language/remove/${uuidId}`)
      .then((response) => {
        console.log(response, "response");
        // setMessage(response.data.message || "Language successfully uploaded.");
        setLoader(false);
        setShowFailed(false);
        apiCallLanguage();
      })
      .catch((error) => {
        console.error("Failed to delete language:", error);
        setLoader(false);
      });
  };

  const showLanguageModal = () => {
    setAddLanguage(true);
  };
  const closeLanguageModal = () => {
    setAddLanguage(false);
  };

  const editData = (data: any) => {
    console.log(data, "edit data ");
    setEditData(data);
    setEditLanguage(true);
  };

  const columns = [
    columnHelper.accessor("sno", {
      cell: () => {
        while (count <= total) {
          count++;
          return count;
        }
      },
      header: () => <span className="">Sr. No.</span>,
    }),
    columnHelper.accessor("languageName", {
      cell: (info) => info.renderValue(),
      header: () => <span>Languages</span>,
    }),
    columnHelper.accessor("Template", {
      cell: (info) => (
        <div className="flex items-center justify-center">
          {info.renderValue()}
          <img src={DirectBox} alt="Attachment" className=" ml-1 w-5 h-5" />
        </div>
      ),
    }),
    columnHelper.accessor("Upload", {
      cell: (info: any) => {
        const languageId = info?.row?.original?.id;
        return <FileUpload languageId={languageId} />;
      },
    }),
    columnHelper.accessor("Action", {
      cell: (info: any) => {
        const value = info?.row?.original?.isActive;
        const uuid = info?.row?.original?.id;
        const edit = info?.row?.original;

        const apiCall = () => {
          axiosUAMInstance
            .put(`/language/toggle`, {
              uuid: uuid,
              isActive: value === true ? false : true,
            })
            .then((responce) => {
              // console.log(responce);
              apiCallLanguage();
            })
            .catch((error) => {
              // console.log(error);
            });
        };

        return (
          <div className="flex flex-col lg:flex-row justify-center items-center gap-1 md:gap-2">
            <div
              className="flex flex-col md:flex-row justify-center gap-3"
              key={Math.random()}
            >
              <span> {value ? "Active" : "Inactive"}</span>
              <ToggleSwitch enabled={value} apiCall={apiCall} />
            </div>
            {/* <div>
              <ActionButton variant="edit" onClick={() => editData(edit)} />
            </div> */}
            {/* <div
              className="md:-mt-1 lg:-md-1 cursor-pointer"
              onClick={() => showFailedModal(uuid)}
            >
              <img src={DeleteIcon} alt="icon" />
            </div> */}
          </div>
        );
      },
    }),
  ];
  return (
    <Layout
      layout={
        <div
          className="md:px-8 px-1 pb-[44px] relative"
          style={{ minHeight: "calc(100vh - 110px)" }}
        >
          <div className="mt-4">
            <CmsTabs />
          </div>
          <div className="my-6">
            <SubTabs />
          </div>
          <div className="">
            <div className=" flex  justify-between  items-center flex-wrap">
              <div>
                <div className="mb-2">
                  <label
                    htmlFor="Country Search"
                    className="text-base font-normal text-gilroy-medium "
                  >
                    Language Search
                  </label>
                </div>
                <div className="md:w-[480px] xl:w-[820px] 2xl:w-[800px] w-[300px]">
                  <InputFields
                    height="40px"
                    // width="500px"
                    padding="10px"
                    placeholder="search here"
                    value={searchText}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="flex items-center mt-5  ">
                <div className="mt-2">
                  <button
                    className="p-3 text-[#1c468e] h-10 rounded-lg border border-[#1c468e] text-base text-gilroy-medium flex  items-center justify-center"
                    onClick={showLanguageModal}
                  >
                    <img src={Add} alt="Add" className="mr-2" />
                    Add New language
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 mb-20">
            {loader ? (
              <LoaderSpin />
            ) : languageData.length > 0 ? (
              <ReactTable
                defaultData={languageData}
                columns={columns}
                borderB={false}
              />
            ) : (
              <div className=" flex justify-center items-center">
                <h1>No data available</h1>
              </div>
            )}
          </div>

          <div className="mt-10 absolute bottom-0">
            {languageData && languageData?.length > 0 && (
              <CustomPagination
                bottomLine={true}
                currentPage={page}
                setCurrentPage={setPage}
                totalItems={total}
                itemsPerPage={pageSize}
                maxPageNumbersToShow={5}
              />
            )}
          </div>

          {showFailed && (
            <FailedModal
              closePopup={closeFailedModel}
              showPopup={deleteLanguage}
              loader={loader}
            />
          )}

          {addLanguage && (
            <UploadLanguage
              onClose={closeLanguageModal}
              onSave={() => {}}
              apiCallLanguage={apiCallLanguage}
            />
          )}

          {editLanguage && (
            <EditLanguage
              onClose={() => setEditLanguage(false)}
              onSave={() => {}}
              apiCallLanguage={apiCallLanguage}
              editData={EditData}
            />
          )}
        </div>
      }
    />
  );
};

export default CsmMasterLanguage;
