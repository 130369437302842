import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import EditRegistrationOtpModal from "../../components/cms/EditRegistrationOtpModal";
import EditRegistrationOtpModalTwo from "../../components/cms/EditRegistrationOtpModalTwo";
import { createColumnHelper } from "@tanstack/table-core";
import ActionButton from "../../components/common/ActionButton";
import ReactTable from "../../components/common/ReactTable";
import CustomPagination from "../../components/cms/Pagination/CustumPagination";
import moment from "moment";
import { axiosUAMInstance } from "../../utils/axios";
import LoaderSpin from "../../components/LoaderSpin";

interface Entity {
  id: number;
  name: string;
  subject: string;
  updated_at: string;
  action: string;
}

const columnHelper = createColumnHelper<Entity>();

const ActionCell: React.FC<{ value: string; data: any }> = ({
  value,
  data,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleActionClick = (action: string) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="flex flex-row justify-center">
        <div>
          <ActionButton
            variant="edit"
            onClick={() => handleActionClick(value)}
          />
        </div>
      </div>
      {isModalOpen && (
        <>
          {value === "sms" && (
            <EditRegistrationOtpModal data={data} onClose={closeModal} />
          )}
          {value === "email" && (
            <EditRegistrationOtpModalTwo onClose={closeModal} data={data} />
          )}
        </>
      )}
    </>
  );
};

const CmsTemplate = () => {
  const [currentAction, setCurrentAction] = useState<string>("sms");
  const [smsData, setSmsData] = useState<Entity[]>([]);
  const [emailData, setEmailData] = useState<Entity[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState(false);
  let count: number;

  const serialNoGen = (page: number) => {
    count = (page - 1) * 10;
  };
  serialNoGen(currentPage);

  const columns = [
    columnHelper.accessor("id", {
      cell: () => {
        while (count <= total) {
          count++;
          return count;
        }
      },
      header: () => <span>S.No.</span>,
    }),
    columnHelper.accessor("name", {
      cell: (info) => info.renderValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("subject", {
      cell: (info) => info.renderValue(),
      header: () => (
        <span>{currentAction === "sms" ? "Template" : "Subject"}</span>
      ),
    }),
    columnHelper.accessor("updated_at", {
      cell: (info) => moment(info.getValue()).format("DD/MM/YYYY"),
      header: () => <span>Modify Date</span>,
    }),
    columnHelper.accessor("action", {
      cell: (info) => (
        <ActionCell value={info.getValue()} data={info.row.original} />
      ),
      header: () => <span>Action</span>,
    }),
  ];

  useEffect(() => {
    const fetchData = currentAction === "sms" ? smsApiCall : emailApiCall;
    fetchData(currentPage, itemsPerPage);
  }, [currentAction, currentPage, itemsPerPage]);

  const smsApiCall = (page: any, limit: any) => {
    setLoader(true);
    axiosUAMInstance
      .get(`/template/list/sms?page=${page}&limit=${limit}`)
      .then((response) => {
        const { list, totalCount } = response.data.data;
        setSmsData(list.map((entity: any) => ({ ...entity, action: "sms" })));
        setTotal(totalCount);
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  const emailApiCall = (page: any, limit: any) => {
    setLoader(true);
    axiosUAMInstance
      .get(`/template/list/email?page=${page}&limit=${limit}`)
      .then((response) => {
        const { list, totalCount } = response.data.data;
        setEmailData(
          list.map((entity: any) => ({ ...entity, action: "email" }))
        );
        setTotal(totalCount);
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  const handleFilterChange = (action: string) => {
    setCurrentAction(action);
  };

  return (
    <Layout
      layout={
        <div
          className="relative md:px-8 px-1 pb-[44px]"
          style={{ minHeight: "calc(100vh - 110px)" }}
        >
          <div className="mt-4">
            <CmsTabs />
          </div>
          <div className="flex space-x-2 cursor-pointer w-44 bg-[#E7F0FF] rounded-3xl overflow-hidden my-5 text-gilroy-semibold">
            <div
              onClick={() => handleFilterChange("sms")}
              className={`p-2 w-32 rounded-3xl text-center ${
                currentAction === "sms"
                  ? "bg-[#1c468e] text-white"
                  : "text-gray-500"
              }`}
            >
              SMS
            </div>
            <div
              onClick={() => handleFilterChange("email")}
              className={`p-2 w-32 rounded-3xl text-center ${
                currentAction === "email"
                  ? "bg-[#1c468e] text-white"
                  : "text-gray-500"
              }`}
            >
              Email
            </div>
          </div>
          {currentAction === "sms" ? (
            <>
              <div className="mb-20">
                {loader ? (
                  <LoaderSpin />
                ) : (
                  <>
                    <ReactTable
                      key={JSON.stringify(smsData)}
                      defaultData={smsData}
                      columns={columns}
                    />
                    <div>
                      <CustomPagination
                        bottomLine={false}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalItems={total}
                        itemsPerPage={itemsPerPage}
                        maxPageNumbersToShow={5}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="mb-20">
                {loader ? (
                  <LoaderSpin />
                ) : (
                  <>
                    <ReactTable
                      key={JSON.stringify(emailData)}
                      defaultData={emailData}
                      columns={columns}
                    />
                    <CustomPagination
                      bottomLine={true}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalItems={total}
                      itemsPerPage={itemsPerPage}
                      maxPageNumbersToShow={5}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
      }
    />
  );
};

export default CmsTemplate;
